const common = {
  accept: 'Acceptar',
  active: 'Actiu',
  add: 'Afegir',
  address: 'Adreça',
  adviserComment: 'Comentaris assesor',
  age: 'Edat',
  all: 'Tots',
  articles: 'Articles',
  back: 'Tornar',
  bank: {
    account: 'Num. compte',
    bic: 'BIC',
    control: 'Control',
    controlIban: 'Control IBAN',
    country: 'País',
    entity: 'Entitat',
    office: 'Oficina',
  },
  birthday: 'Data de naixement',
  cancel: 'Cancel·lar',
  category: 'Categoria',
  channel: 'Canal',
  cif: 'CIF',
  city: 'Població',
  clear: 'Netejar',
  client: 'Client',
  clients: 'Clients',
  close: 'Tancar',
  code: 'Codi',
  comment: 'Comentari',
  comments: 'Comentaris',
  confirm: 'Confirmar',
  coordinatorComment: 'Comentarios coordinador',
  country: 'País',
  createdDate: 'Data de creació',
  date: 'Data',
  dateIn: 'Data entrada',
  dateOut: 'Data sortida',
  declarantName: 'Nom del declarant',
  delete: 'Eliminar',
  description: 'Descripció',
  design: 'Disseny',
  destination: 'Destí',
  document: 'Document',
  domicile: 'Domicili',
  edit: 'Editar',
  email: 'Email',
  endDate: 'Data fi',
  endHour: 'Hora fi',
  erpId: 'Expedient',
  error: 'Error',
  errorText: 'S\'ha produït un error. Torneu-ho a provar.',
  exitDate: 'Data sortida',
  exitTime: 'Hora sortida',
  firstSurname: 'Primer cognom',
  frontCamera: 'Càmera frontal',
  fullName: 'Nom i cognoms',
  gender: 'Sexe',
  go: 'Anar a la pàgina',
  hello: 'Hola',
  hour: 'Hora',
  hourIn: 'Hora entrada',
  hourOut: 'Hora sortida',
  id: 'Id',
  identifier: 'DNI',
  identifierDocument: 'Document identificatiu',
  inactive: 'inactiu',
  invoice: 'Albarà',
  kinship: 'Parentiu',
  language: {
    basque: 'Basc',
    catalan: 'Catalá',
    english: 'Anglès – Regne Unit',
    french: 'Francès',
    german: 'Alemany',
    italian: 'Italià',
    spanish: 'Espanyol',
  },
  location: 'Lloc',
  logEntryDate: 'Data d\'entrada del registre',
  masterDataKo: 'S\'ha produït un error al carregar les dades d\'EKON. Si us plau, intenteu-ho de nou.',
  month: 'Mes',
  more: 'més',
  name: 'Nom',
  nationality: 'Nacionalitat',
  next: 'Següent',
  nie: 'Tarjeta de residència',
  no: 'No',
  noItems: 'No hi ha elements',
  notFound: 'No s\'ha trobat',
  observation: 'Observació',
  observations: 'Observacions',
  of: 'de',
  operatorComment: 'Observacions operari',
  order: 'Ordre',
  other: 'Un altre',
  page: 'Pàgina',
  pageSize: 'Mida de pàgina',
  passport: 'Passaport',
  personalObjects: 'Objectes personals',
  phone: 'Telèfon',
  postalCode: 'Codi postal',
  prev: 'Anterior',
  price: 'Preu',
  print: 'Imprimir',
  provider: 'Proveïdor',
  province: 'Província',
  readQR: 'Llegir codi QR',
  remove: 'Eliminar',
  request: 'Sol·licitar',
  save: 'Guardar',
  search: 'Cercar',
  secondSurname: 'Segon cognom',
  see: 'Veure',
  selectFile: 'Triar arxiu',
  send: 'Enviar',
  show: 'Mostrar',
  sign: 'Signar',
  signText: 'Signi en el següent espai',
  size: 'Tamany',
  startDate: 'Data inici',
  startHour: 'Hora inici',
  status: 'Estat',
  street: 'Carrer',
  sure: 'Està segur?',
  surname: 'Cognom',
  thePage: 'La pàgina',
  type: 'Tipus',
  unsaved: 'Tens canvis sense desar, estàs segur que vols sortir?',
  username: 'Nom d\'usuari',
  validate: 'Validar',
  yes: 'Sí',
};
export default common;
